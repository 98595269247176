import { useActions } from '@front/master';
import {
  Container,
  DefaultCard,
  DefaultCards,
  ECardTextColor,
  EDefaultCardBackground,
  EContainerVariant,
  ETitleSize,
  EDefaultCardsAlign,
  EDefaultCardsColumns,
  EDefaultCardsHorizontalScroll,
} from '@front/shared/ds';
import clsx from 'clsx';
import dynamic from 'next/dynamic';
import React, { useMemo } from 'react';

import type { Hero } from '@shared/master-types';

const HeroSectionHomePage = dynamic(() =>
  import(
    /* webpackChunkName: "HeroSectionHomePage" */ '../sections/HeroSectionHomePage'
  ).then(r => r.HeroSectionHomePage),
);

export type THero = {
  hero?: Hero;
};

const Hero: React.FC<THero> = props => {
  const { hero } = props;

  const heroActions = useActions(hero?.actions);

  const fixedAction = useActions(hero?.fixedElement?.action);

  const cards = useMemo(() => hero?.cards || [], [hero?.cards]);
  const banners: React.ComponentProps<typeof DefaultCard>[] = useMemo(() => {
    return cards.map(card => ({
      title: card.title,
      titleSize: ETitleSize.Small,
      description: card.description,
      background: EDefaultCardBackground.Light,
      cardTextColor: ECardTextColor.Dark,
    }));
  }, [cards]);

  return (
    <div
      className={clsx('overflow-hidden 2xl:pb-16', {
        'pb-12': cards.length !== 0,
      })}
    >
      <Container isPadding={false} variant={EContainerVariant.Lead}>
        {hero?.images === 'home' && (
          <HeroSectionHomePage
            fixedAction={fixedAction}
            title={hero?.title}
            subtitle={hero?.subtitle}
            actions={heroActions}
          />
        )}
      </Container>
      {cards.length !== 0 && (
        <div className='mt-6 2xl:mt-4'>
          <DefaultCards
            banners={banners}
            align={EDefaultCardsAlign.Center}
            columns={
              hero?.cards?.length === 2
                ? EDefaultCardsColumns.Two
                : EDefaultCardsColumns.Four
            }
            horizontalScroll={EDefaultCardsHorizontalScroll.Mobile}
          />
        </div>
      )}
    </div>
  );
};

export default Hero;
