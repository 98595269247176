import { EContainerVariant, toImageProps, Container } from '@front/shared/ds';
import { Media } from '@shared/master-types';
import Image, { ImageProps } from 'next/image';

export type TCustomImagesRow = {
  customType: 'cost-comparison';
  images: {
    image: string | Media;
    id?: string;
  }[];
  id?: string;
  blockName?: string;
  blockType: 'custom-images';
};

type TCustomSpaceStartImageRowProps = {
  row: TCustomImagesRow;
};

export const CustomSpaceStartImageRow: React.FC<
  TCustomSpaceStartImageRowProps
> = ({ row }) => {
  if (!row.images.length) {
    return null;
  }

  const nextImageProps: ImageProps | null = toImageProps(row.images[0].image);

  if (!nextImageProps) {
    return null;
  }

  return (
    <Container variant={EContainerVariant.Default}>
      <div className='scrollbar-hidden -mx-4 flex overflow-auto'>
        <Image
          className='m-auto max-h-[22.5rem] w-auto max-w-none px-4'
          {...nextImageProps}
          alt={nextImageProps.alt}
        />
      </div>
    </Container>
  );
};
