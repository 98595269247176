import { SectionParser, useGetLocalesQuery } from '@front/master';
import { Section } from '@shared/master-types';
import React from 'react';

import {
  CustomSpaceStartImageRow,
  TCustomImagesRow,
  CustomSpaceCardRow,
  TCustomSpaceCardRow,
} from '../../../rows';

export type TSectionsListProps = {
  sections: Section[];
  locale: string;
  draft: boolean;
};

export const SectionsList: React.FC<TSectionsListProps> = props => {
  const { sections, draft, locale } = props;

  const { data: localesObject } = useGetLocalesQuery({ locale, draft });

  const locales = localesObject?.docs;

  const customSectionRowRenderer = (
    row: TCustomImagesRow | TCustomSpaceCardRow,
  ): React.ReactNode => {
    if (row.customType === 'cost-comparison') {
      return <CustomSpaceStartImageRow key={row.id} row={row} />;
    }

    if (row.customType === 'space-card') {
      return <CustomSpaceCardRow key={row.id} row={row} />;
    }

    return undefined;
  };

  return (
    <>
      {sections?.map(section => (
        <SectionParser
          key={section.id}
          section={section}
          locales={locales}
          customSectionRowRenderer={customSectionRowRenderer}
        />
      ))}
    </>
  );
};
