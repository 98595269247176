import { getGaId } from '@front/shared';
import {
  Button,
  EButtonSize,
  EButtonVariant,
  ECardWrapperLayout,
  RichText,
  toImageProps,
  Card,
  ETitleSize,
  ECardTextColor,
  ICardWrapperProps,
  ECardWrapperBackground,
  ETagVariant,
  ETagSize,
  ETagTheme,
  TCardBulletsProps,
  TCardImageRawProps,
} from '@front/shared/ds';
import clsx from 'clsx';
import Image from 'next/image';
import React, { useState } from 'react';

import type { Section } from '@shared/master-types';

export type TCustomSpaceCardRow = Extract<
  Required<Section>['rows'][number],
  { blockType: 'banner-cards' }
> & { customType: 'space-card' };

type TCustomSpaceCardBanner = ICardWrapperProps &
  Pick<Required<TCustomSpaceCardRow>['cards'][number], 'tabTitle'>;

type TCustomSpaceCardRowProps = {
  row: TCustomSpaceCardRow;
};

export const CustomSpaceCardRow: React.FC<TCustomSpaceCardRowProps> = ({
  row,
}) => {
  const [currentBannerIndex, setCurrentBannerIndex] = useState(0);

  const banners: TCustomSpaceCardBanner[] = (row.cards || []).map(card => ({
    ...card,
    background: card.background as ECardWrapperBackground,
    size: card.size as ECardWrapperLayout,
    titleSize: card.titleSize as ETitleSize,
    description: <RichText content={card.description} />,
    icon: toImageProps(card.icon),
    image: toImageProps(card?.banner?.media),
    badgesStyle: card.badgesStyle as ETagVariant.Contained | ETagVariant.Text,
    badgesSize: card.badgesSize as ETagSize,
    badgesTheme: card.badgesTheme as ETagTheme,
    buttons: [], //TODO: add toCardButtons
    bullets: card.bullets as TCardBulletsProps,
    imageRow: {
      ...card.imageRow,
      images: card.imageRow?.images?.map(img => toImageProps(img.media)) || [],
    } as TCardImageRawProps,
  }));

  return (
    <section className={clsx('grid grid-cols-1 gap-6 px-4 lg:grid-cols-none')}>
      <div className='scrollbar-hidden -mx-4 flex max-w-[100vw] overflow-auto scroll-smooth px-4 md:mx-auto lg:max-w-[54rem]'>
        <div className='flex min-w-fit gap-2 lg:flex-wrap lg:justify-center'>
          {banners.map((card, index) => (
            <Button
              key={index}
              variant={
                currentBannerIndex === index
                  ? EButtonVariant.Contained
                  : EButtonVariant.Outlined
              }
              size={EButtonSize.Small}
              onClick={() => setCurrentBannerIndex(index)}
              className='whitespace-nowrap border border-brand-500/90'
              data-ga-id={getGaId(index.toString(), row.blockName, 'tab')}
            >
              {card.tabTitle || card.title}
            </Button>
          ))}
        </div>
      </div>
      <div className='m-auto w-full max-w-[65rem] 2xl:max-w-[78.5rem]'>
        <div
          className={clsx(
            'flex flex-col gap-6 rounded-3xl bg-surface-800 p-6 2xl:flex-row 2xl:items-center 2xl:rounded-[2rem] 2xl:p-8',
          )}
        >
          <div className='flex-1 md:min-w-[21rem]'>
            <Card
              cardTextColor={ECardTextColor.Light}
              titleSize={ETitleSize.Small}
              {...banners[currentBannerIndex]}
            />
          </div>
          {banners[currentBannerIndex]?.image && (
            <div className='scrollbar-hidden -mx-10 flex-1 basis-auto overflow-hidden overflow-x-scroll'>
              <div className='w-full max-w-[100vw]'>
                <Image
                  {...banners[currentBannerIndex].image!}
                  alt={banners[currentBannerIndex].image!.alt}
                  style={
                    banners[currentBannerIndex].image?.width
                      ? {
                          minWidth: banners[currentBannerIndex].image?.width,
                        }
                      : undefined
                  }
                  className='w-full max-w-none px-10'
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};
